@font-face {
  font-family: 'Titillium Web Regular';
  src: url('./fonts/TitilliumWeb-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Titillium Web SemiBold';
  src: url('./fonts/TitilliumWeb-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Titillium Web Bold';
  src: url('./fonts/TitilliumWeb-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@import "leaflet/dist/leaflet.css";


body {
  font-family: 'Titillium Web Regular', sans-serif;
}

h2 {
  font-family: 'Titillium Web SemiBold', sans-serif;
}


h3 {
  font-family: 'Titillium Web SemiBold', sans-serif;
}

h4 {
  font-family: 'Titillium Web SemiBold', sans-serif;
}


hr {

  color: #aaa;

}


select.form-control option {
  font-family: 'Titillium Web', sans-serif !important;
}

option {
  font-family: 'Titillium Web', sans-serif !important;
}



select.form-control option {
  font-family: 'Titillium Web', sans-serif !important;
  color: #0C6594 !important


}

.form-select option{
  font-family: 'Titillium Web', sans-serif !important;

}

/* Stile quando il valore di default è selezionato */
select.form-select.default-selected {
  color: rgba(33, 37, 41, 0.75);  /* Colore del testo quando "Default" è selezionato */
}

/* Colore normale per le altre opzioni */
select.form-select option {
  color: #333;  /* Colore delle altre opzioni */
}



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/* CUSTOM CODE /*/

.home-title {

  font-size: 50px;
  font-family: 'Titillium Web Bold';
  color: #0C6594;

}

.box-result h5:hover {

  text-decoration: underline;

}

.tumb-image {

  background-size: cover;
  background-position: center;
 
  /* Evita che il div si ridimensioni */
  border-radius: 5px 0px 0px 5px !important;

}


.logo-text {

  font-family: 'Titillium Web Regular';
  color: #fff;
  font-family: 'Titillium Web SemiBold';
  font-family: 'Titillium Web SemiBold';

}



.apt-bg-color-1 {

  background-color: #0C6594;

}

.apt-bg-color-2 {

  background-color: #004F78;

}


.apt-color-1 {

  color: #0C6594;

}



.apt-color-2 {

  color: #004F78;

}

.search-btn {

  color: #fff;
  font-family: 'Titillium Web SemiBold';

}

.search-btn:hover {

  color: #004F78;
  font-family: 'Titillium Web SemiBold';
  background-color: #f9f9f9;

}


.bold-text {

  font-family: 'Titillium Web Bold';

}

.semibold-text {

  font-family: 'Titillium Web SemiBold';
  font-size: 18px;

}


.extra-bold-text {

  font-family: 'Titillium Web Bold';

}



/* BOX CODE /*/


.box-container {

  display: block;
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
  color: inherit;

}


.box-container h3:hover {

  display: block;
  border-radius: 10px;
  overflow: hidden;
  text-decoration: underline;

}

.box-image {

  height: 350px;
  /* Imposta l'altezza dell'immagine */
  border-radius: 5px 5px 0 0;
  /* Bordi arrotondati solo per la parte superiore */
  position: relative;

}

.box-image-overlay {

  background: rgba(0, 0, 0, 0.4);
  /* Sfondo scuro semitrasparente per l'effetto hover */
  transition: opacity 0.3s ease;
  opacity: 0;
  /* L'immagine non deve cambiare quando hover */
  border-radius: 5px 5px 0 0;
  /* Bordi arrotondati per l'effetto hover */

}

.box-text {

  /* Imposta l'altezza del testo */
  color: #333;
  border-radius: 0 0 5px 5px;
  /* Bordi arrotondati solo per la parte inferiore */
  font-size: 16px;


}


.card-tipologia {

  font-size: 14px;
  color: #333;
  font-family: 'Titillium Web SemiBold';

}


.card-prezzo {

  font-size: 14px;
  color: #333;
  font-family: 'Titillium Web Regular' !important;

}


.btn-struttura {

  background-color: #0C6594;
  color: #fff;
  font-size: 15px;

}

.btn-struttura:hover {

  background-color: #004F78;
  color: #fff;
  font-size: 15px;

}


.btn-nuova {

  background-color: #0C6594;
  color: #fff;
  font-size: 16px;
  font-family: 'Titillium Web SemiBold';

}


.btn-nuova:hover {

  background-color: #004F78;
  color: #fff;
  font-size: 16px;
  font-family: 'Titillium Web SemiBold';


}


.struttura-result h5:hover {

  text-decoration: underline;
}

.card-text {

  font-family: 'Titillium Web Regular';

}


.classificazione {

  color: #222;
  font-family: 'Titillium Web SemiBold';
  font-size: 16px;

}

.indietro {

  color: #fff;

}

.indietro:hover {

  color: #fff;
  background-color: #004F78;

}

.details-icon {

  margin-right: 5px;

}


.details-text {

  margin-right: 15px;

}




.breadcrumb  {

  font-size: 15px;
  color: #0C6594;

}


.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 25px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.005);
  z-index: 1000;
  max-width: 90%;  /* Limita la larghezza massima su schermi piccoli */
  width: 1150px;    /* Larghezza predefinita */
  min-width: 300px; /* Imposta una larghezza minima */
  border-radius: 5px;

}

/* Overlay che copre lo schermo */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
}



.popup2 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 25px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.005);
  z-index: 1000;
  max-width: 90%;  /* Limita la larghezza massima su schermi piccoli */
  width: 1150px;    /* Larghezza predefinita */
  min-width: 300px; /* Imposta una larghezza minima */
  border-radius: 5px;

}

/* Overlay che copre lo schermo */
.overlay2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.03);
  z-index: 999;
}

.text-popup {

  font-family: 'Titillium Web Regular';
  font-size: 16px;

}


.nav-text {
  font-family: 'Titillium Web SemiBold';
}

.mappa {

  width: 100%;
  height: 90%;
  margin: 0px;
  padding: 0px;
}


.info {
  font-size: 13px;
}






@media only screen and (max-width: 600px) {

  .tumb-image {
    background-size: cover;
    background-position: center;
    width: 100%;
    /* Imposta la larghezza per adattarsi alla card */
    height: 100%;
    /* Imposta l'altezza per adattarsi al contenuto dinamico */
    min-height: 220px;
    /* Altezza minima per gestire layout uniformi */
    flex-shrink: 0;
    /* Evita che il div si ridimensioni */
    border-radius: 5px 5px 0px 0px !important;
  }

  .social {

    gap: 1em;

  }


  .home-title {
    font-size: 40px;
    font-family: 'Titillium Web Bold';
    color: #0C6594;
  }

  
.classificazione {

  color: #222;
  font-family: 'Titillium Web SemiBold';
  font-size: 14px;

}

.popup {
  width: 100%;  /* Riduci la larghezza sui dispositivi mobili */
  padding: 10px; /* Riduci il padding sui dispositivi mobili */
  padding: 20px;
  font-size: 14px;

}


.popup2 {
  width: 100%;  /* Riduci la larghezza sui dispositivi mobili */
  padding: 10px; /* Riduci il padding sui dispositivi mobili */
  padding: 20px;
  font-size: 14px;

}

.mappa {

  width: 100%;
  height: 80%;
  margin: 0px;
  padding: 0px;
}

.info {

 text-align: center !important;

}

.divpopup {

padding-left: 15px !important;
padding-right: 15px !important;

}

}


.star {
  color: #F8D229;
  padding: 0px !important;
  margin: 0px !important;
  width: 8px;
  margin-bottom: 9px !important;
}


.pagination {

  display: flex;
  flex-wrap: wrap;
  /* Permette ai pulsanti di andare a capo */
  justify-content: center;
  /* Centra i pulsanti */

}


/* .bg-home {

  background-image: url("images/maratea.jpeg");
  background-size: cover;
  background-position: center; 

} */

.filtri {

  color: #0C6594;
  font-size: 16px;
  text-decoration: underline;
  background-color: transparent;
  border: none;

}

.filtri:hover {

  color: #ccc;
  font-size: 16px;
  text-decoration: underline;
  background-color: transparent;
  border: none;


}

.title {

  font-size: 40px;
  font-family: 'Titillium Web Bold';
  color: #fff;


}

/* Griglia di immagini */
.gallery-grid {
  display: grid;
  grid-template-columns: repeat(25, 1fr);
  /* 5 colonne */
  gap: 10px;
  /* Spazio tra le immagini */
}

.gallery-item {
  width: 100%;
  padding: 0;
  cursor: pointer;
}

.gallery-thumbnail {
  width: 100%;
  height: 200px;
  /* Thumbnail di 300px x 300px */
  object-fit: cover;
  /* Ritaglia l'immagine mantenendo proporzioni */
  border-radius: 8px;
  /* Optional: arrotonda gli angoli */
  transition: transform 0.3s;
  /* Effetto hover */
}

.gallery-thumbnail:hover {
  transform: scale(1.05);
  /* Leggero zoom al passaggio del mouse */
}

.image-cover {

  width: 100%;
  height: 300px;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;

}

.box-container:hover {

  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1) !important;
  transition-duration: 150ms !important;
}


.description {

  font-size: 14px;
  padding-top: 5px;

}

.result_tipologia {

font-size: 13px;

}


.header_cover {

  width: 100%;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;

}


.bg-filter {

background-color: #F2F7FC !important;

}

